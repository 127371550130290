define('utils',[],function(){
    return {
        /**
         * Der Callback wird aufgerufen nachdem debounce() `threshold` Millisekunden lang nicht mehr aufgerufen wird.
         *
         * Der Callback wird gleich aufgerufen wie der letzte Aufruf von debounce().
         *
         * Wenn threshold nicht angegeben wird, wird es versucht, mit dem Bildschirm zu sychronisieren.
         *
         * @example
         * $(window).on('scroll', utils.debounce(function(){
         *      // Das wird erst aufgerufen, wenn das Scrollen aufhört
         * }));
         *
         * @param {Function} fn
         * @param {number} [threshhold]
         * @returns {Function}
         */
        debounce: function(fn, threshhold) {
            threshhold = threshhold || 1000/60;
            var timer;
            return function(){
                var run = (function(args){
                    fn.apply(this, args);
                }).bind(this, arguments);

                clearTimeout(timer);
                timer = setTimeout(run, threshhold);
            };
        },

        /**
         * Die Aufrufe werden auf 1x pro `threshold` Millisekunden begrenzt.
         *
         * Die erste und letzte Aufrufe werden immer ausgeführt.
         *
         * Wenn threshold nicht angegeben wird, wird es versucht, mit dem Bildschirm zu sychronisieren.
         *
         * @example
         * $(window).on('scroll', utils.throttle(function(){
         *      // Das wird höchstens alle 100ms aufgerufen
         * }, 100));
         *
         * @param {Function} fn
         * @param {number} [threshhold]
         * @returns {Function}
         */
        throttle: function(fn, threshhold) {
            var next = function(fn) { setTimeout(fn, threshhold); };
            // Wenn threshhold nicht angegeben, mit Bildshirm synchronizieren
            if (!threshhold) {
                threshhold = 1000/60;
                next = window.requestAnimationFrame ||
                       window.webkitRequestAnimationFrame ||
                       window.mozRequestAnimationFrame ||
                       window.oRequestAnimationFrame ||
                       next;
            }

            var waiting = false,
                runAfterWait = false,
                run;
            return function() {
                run = (function(args){
                    runAfterWait = false;
                    fn.apply(this, args);
                }).bind(this, arguments);

                if (waiting) {
                    runAfterWait = true;
                } else {
                    run();
                    waiting = true;
                    next(function() {
                        waiting = false;
                        if (runAfterWait) {
                            run();
                        }
                    });
                }
            };
        }
    };
});


define('main',['jquery', 'utils'], function($, util){
    $(function(){
        var $win = $(window),
            $sections = $('section'),
            winHeight,
            videoHeight;
        $win
            .on('resize load', util.throttle(function(){
                winHeight = $win.height();
                videoHeight = $('#video').height();
                $sections.each(function(){
                    var $this = $(this);
                    $this.data('showAt', Math.min(
                        $this.offset().top + window.innerHeight/2,
                        $this.offset().top + $this.outerHeight()/2
                    ));
                });
                $win.trigger('scroll');
            }))
            .on('scroll', util.throttle(function(){
                var scrollTop = $win.scrollTop(),
                    scrollBottom = scrollTop + window.innerHeight;
                $sections.each(function(){
                    var $this = $(this),
                        showAt = $this.data('showAt');
                    $this.toggleClass('hide', showAt > scrollBottom);
                    $this.toggleClass('show', showAt <= scrollBottom);
                });
            }))
            .trigger('resize');

        if (document.cookie.match(/(^|;)\s*video-finished=1\s*(;|$)/)) {
            $('html').addClass('video-finished');
        }

        window.onYouTubeIframeAPIReady = function(){
            var player = new YT.Player('video-iframe');
            player.addEventListener('onStateChange', function onStateChange(e){
                if (e.data === YT.PlayerState.ENDED) {
                    $('html').addClass('video-finished');
                    document.cookie = 'video-finished=1; path=/; expires=' + (new Date()).toUTCString();

                    var scrollTo = videoHeight * .7;
                    if ($win.scrollTop() < scrollTo) {
                        $("html, body").animate({scrollTop: scrollTo}, 1000);
                    }

                    player.removeEventListener('onStateChange', onStateChange);
                }
            });
        };
        require(['https://www.youtube.com/iframe_api']);
    });
});
(function(k,o,n,a,m,i){addEventListener('keydown',function(e){e.which!=k[o]?o=0:++o==k.length&&(function(){i=n.createElement(a);i.style=m;n.body.appendChild(i);})()})})([38,38,40,40,37,39,37,39,66,65],0,document,'div',"background:url('//github.com/jmhobbs/cultofthepartyparrot.com/raw/master/parrots/hd/parrot.gif');position:fixed;left:0;right:0;bottom:0;height:128px;z-index:999999");
